@use '@angular/material' as mat;
@import 'material.palettes';

.paidiem-theme {
  .flex-mat-suffix {
    .mat-mdc-form-field-icon-suffix {
      display: flex;
    }
  }
  .mat-mdc-option:not(:last-child),
  .mat-mdc-menu-item:not(:last-child) {
    @apply border-b-faded-50-light;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  .mat-expansion-panel-header-title {
    @apply text-lg font-semibold;
  }
  .mdc-tab,
  .mat-mdc-tab-link:not(.mdc-tab--stacked) {
    min-height: 65px;
    height: auto !important;
    letter-spacing: normal;
    .mdc-tab-indicator {
      @apply border-b-faded-50-light;
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }
    &.mdc-tab--active {
      font-weight: bold;
      background: #f6f5fa;
    }
  }
  .mdc-dialog .mdc-dialog__content {
    padding: 16px;
  }
  .mdc-dialog__container pd-header {
    @apply ml-4;
  }
  .no-spacer {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
  .mat-datepicker-toggle-default-icon {
    width: 42px;
  }
  .mdc-dialog__title {
    @apply px-4 pb-2;
  }
  .mat-mdc-dialog-container {
    [mat-dialog-content] {
      color: #000000;
    }
    color: #000000;
  }

  .mat-mdc-dialog-panel {
    @media (max-width: 600px) {
      min-width: 90vw !important;
    }
  }

  mat-datepicker-toggle {
    display: inline-block;
  }
  .mat-form-field--condensed {
    .mat-mdc-text-field-wrapper {
      height: 58px;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    input.mat-mdc-input-element {
      &::placeholder {
        @apply italic;
      }
      line-height: 2.5rem;
    }
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      min-height: 58px;
      width: 100px;
      @apply py-2;
    }
  }
  .mat-form-field--extra-condensed {
    .mat-mdc-text-field-wrapper {
      height: 44px;
    }
  }
  .mat-form-field--rounded {
    .mat-mdc-text-field-wrapper {
      border-radius: 32px !important;
    }
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    @apply h-15;
    @apply p-2;
    .mat-mdc-button-touch-target {
      @apply h-15;
    }
  }
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }
  .mat-expansion-panel {
    @apply border border-faded-50-light;
  }
  .mat-accordion mat-expansion-panel.mat-expansion-panel {
    @apply rounded-md;
  }
  .mat-mdc-form-field-icon-suffix {
    z-index: 1;
  }
  mat-form-field.no-spacer .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-line-ripple {
    display: none;
  }

  .mdc-text-field--filled {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    &.mdc-text-field--focused {
      border: 1px solid map-get($primary-palette, 400);
    }

    &.mdc-text-field--disabled {
      border: 1px solid rgba(0, 0, 0, 0.06);
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .mat-expansion-indicator,
  .mat-expansion-indicator::after {
    @apply border-gray-550;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--invalid {
    border: 1px solid map-get($warning-palette, 400);
  }
  .mdc-text-field:not(.mdc-text-field--disabled):hover {
    border: 1px solid rgba(0, 0, 0, 0.6);
    &.mdc-text-field--focused {
      border: 1px solid map-get($primary-palette, 400);
    }
  }
  &.dark {
    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }
    .mdc-text-field--filled {
      border: 1px solid rgba(255, 255, 255, 0.42);

      &.mdc-text-field--disabled {
        border: 1px solid rgba(255, 255, 255, 0.06);
        color: rgba(255, 255, 255, 0.25);
      }

      &.mdc-text-field--focused {
        border: 1px solid map-get($dark-primary-palette, 400);
      }
    }
    .mat-mdc-option,
    .mat-mdc-menu-item {
      @apply border-b-faded-100-dark;
    }
    .mdc-tab,
    .mat-mdc-tab-link:not(.mdc-tab--stacked) {
      .mdc-tab-indicator {
        @apply border-b-faded-100-dark;
      }
      &.mdc-tab--active {
        @apply bg-gray-600;
      }
    }
    .mat-expansion-indicator,
    .mat-expansion-indicator::after {
      @apply border-gray-200;
    }
    .mat-mdc-dialog-container {
      color: #ffffff;
      [mat-dialog-content] {
        color: #ffffff;
      }
    }
    .mat-expansion-panel {
      @apply border-faded-100-dark;
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
      border: 1px solid rgba(255, 255, 255, 0.42);
    }
    .mdc-text-field:not(.mdc-text-field--disabled):hover {
      border: 1px solid rgba(255, 255, 255, 0.87);
      &.mdc-text-field--focused {
        border: 1px solid map-get($dark-primary-palette, 400);
      }
    }

    .mat-date-range-input-inner::placeholder {
      @apply text-faded-50-light;
    }
  }
  // fixes mat-accordions in panels/modals which are expanded until the animation
  // for the modal/panel is completed and then collapses
  .ng-animating .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
    height: 0;
    visibility: hidden;
  }
  button,
  input,
  optgroup,
  select,
  textarea,
  a {
    @apply text-sm;
    box-shadow: none;
  }
}
