@import '@fontsource/inter';
@import '@fontsource/inter/100.css';
@import '@fontsource/inter/200.css';
@import '@fontsource/inter/300.css';
@import '@fontsource/inter/400.css';
@import '@fontsource/inter/500.css';
@import '@fontsource/inter/600.css';
@import '@fontsource/inter/700.css';
@import '@fontsource/inter/800.css';
@import '@fontsource/inter/900.css';
@import '@fortawesome/fontawesome-free/css/brands.css';
@import '@fortawesome/fontawesome-free/css/regular.css';
@import '@fortawesome/fontawesome-free/css/solid.css';
@import '@fortawesome/fontawesome-free/css/fontawesome.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// our base overrides
@import './sass/tailwind.utils';
@import './sass/material.theme';
@import './sass/material.overrides';

// our styles
@import './sass/animations/breathing';
@import './sass/animations/pulse';
@import './sass/tables';

html,
body {
  height: 100%;
  font-size: 16px;
  margin: 0;
  font-family: 'Inter', sans-serif;
}
hr {
  @apply my-1;
}
