@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Can directly apply colors : hard coded values for light and dark */
  // .bg-color {
  //   @apply bg-white dark:bg-black;
  // }

  /* Can use custom color defined in the tailwind.config.css file */
  // .bg-text {
  //   @apply text-light-primary-800 dark:text-dark-primary-500;
  // }

  /* This is how you apply the border-color for both light and dark mode */
  .border-dynamic {
    @apply border-faded-50-light dark:border-faded-100-dark;
  }
  .border-l-dynamic {
    @apply border-l-faded-50-light dark:border-l-faded-100-dark;
  }
  .border-r-dynamic {
    @apply border-r-faded-50-light dark:border-r-faded-100-dark;
  }
  .border-b-dynamic {
    @apply border-b-faded-50-light dark:border-b-faded-100-dark;
  }
  .border-t-dynamic {
    @apply border-t-faded-50-light dark:border-t-faded-100-dark;
  }
  .border-x-dynamic {
    @apply border-x-faded-50-light dark:border-x-faded-100-dark;
  }
  .text-label {
    @apply text-faded-200-light dark:text-faded-200-dark;
  }
  .text-input-button {
    @apply text-faded-150-light dark:text-faded-150-dark;
  }
  .text-primary-dynamic {
    @apply text-primary dark:text-primary-light;
  }
  .bg-base-dynamic {
    @apply bg-white dark:bg-gray-700;
  }
  .bg-dynamic {
    @apply bg-gray-75 dark:bg-gray-600;
  }
  .table-header {
    @apply bg-header p-4 text-xs font-semibold uppercase;
  }
}

[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: transparent;
}
hr {
  @apply border-dynamic;
}
