.pd-table {
  position: relative;
  @apply bg-white dark:bg-gray-800;
  @apply border-dynamic overflow-x-auto rounded border;

  table {
    @apply w-full table-auto;
    thead tr,
    tr:not(:last-child) {
      @apply border-b-dynamic border-b;
    }

    tbody:empty::after {
      content: '';
      display: block;
      min-height: 125px; /* Your desired minimum height */
    }

    thead {
      @apply text-sm leading-4;

      th {
        @apply bg-gray-100 p-2 text-left text-base text-black dark:bg-gray-600 dark:text-white;
        height: 52px;
        .mat-sort-header-arrow {
          @apply text-black dark:text-white;
        }

        font-weight: 600;

        div {
          display: flex;
          align-items: center;

          i {
            padding-left: 0.5rem;
            font-size: 0.75rem;
            line-height: 1rem;
            color: rgb(172, 179, 190);
          }
        }
      }

      tr th:first-child {
        padding-left: 0.5em;
        @media (min-width: 600px) {
          padding-left: 1rem;
        }
        @media (min-width: 900px) {
          padding-left: 1.5rem;
        }
      }

      tr th:last-child {
        padding-right: 0.5em;
        @media (min-width: 600px) {
          padding-right: 1rem;
        }
        @media (min-width: 900px) {
          padding-right: 1.5rem;
        }
      }
    }

    tbody {
      td {
        @apply py-2;
        @apply px-3;
        height: 52px;
        max-height: 52px;
        white-space: nowrap;
      }

      tr:hover {
        @apply bg-gray-50 dark:bg-gray-700;
      }

      tr td:first-child {
        padding-left: 0.5em;
        @media (min-width: 600px) {
          padding-left: 1rem;
        }
        @media (min-width: 900px) {
          padding-left: 1.5rem;
        }
      }

      tr td:last-child {
        padding-right: 0.5em;
        @media (min-width: 600px) {
          padding-right: 1rem;
        }
        @media (min-width: 900px) {
          padding-right: 1.5rem;
        }
      }

      * {
        text-overflow: ellipsis;
      }
    }
  }

  pd-loader {
    @apply flex items-center justify-center;
    mat-spinner {
      @apply m-4;
    }
  }
}
