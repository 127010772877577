.anim-breathing {
  -webkit-animation: breathing 3s ease-out infinite normal;
  animation: breathing 3s ease-out infinite normal;
}
$smallestScale: 0.9;
$largestScale: 1;

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale($smallestScale);
    transform: scale($smallestScale);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale($smallestScale);
    transform: scale($smallestScale);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale($smallestScale);
    -ms-transform: scale($smallestScale);
    transform: scale($smallestScale);
  }

  50% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale($smallestScale);
    -ms-transform: scale($smallestScale);
    transform: scale($smallestScale);
  }
}
