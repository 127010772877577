// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import 'material.palettes';

@include mat.core();

$paidiem-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warning-palette,
    ),
    typography: $font-config,
    density: 0,
  )
);

$paidiem-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary-palette,
      accent: $dark-palette,
      warn: $warning-palette,
      success: $green-palette,
    ),
  )
);

// TODO: Revisit and only include components we use
.paidiem-theme {
  @include mat.all-component-themes($paidiem-light-theme);

  &.dark {
    @include mat.all-component-colors($paidiem-dark-theme);
  }
}
